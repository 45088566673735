import React from 'react';
import Helmet from 'react-helmet';

import {
  Card,
  Tabs,
  Tab,
} from '@blueprintjs/core';

import Layout from '../components/layout';
import PrintButton from '../components/PrintButton';
import ZoneRawData from '../components/ZoneRawData';
import ZoneDesc from '../components/ZoneDesc';
import styles from './zone.module.scss';

const topBarProps = {
  pageTitle: "Lister les espaces d'activités",
  buttonText: '‹ retour à la liste',
  href: '/espaces-d-activites',
  returnList: true,
};

const ZonePage = ({ pageContext: { zone } }) => (
  <>
    <Layout topBarProps={topBarProps}>
      <Card className={styles.mainCard}>
        <div className={styles.titleWrapper}>
          <h1 style={{ marginTop: 0 }}>{zone.nom_ppal}</h1>
          <PrintButton className={styles.printButton} />
        </div>

        <h2>{zone.libepci}</h2>

        <Tabs>
          <Tab title="Description" id="dummy" panel={<ZoneDesc zone={zone} />} />
          <Tab title="Toutes les valeurs" id="data" panel={<ZoneRawData zone={zone} />} />
        </Tabs>
      </Card>

    </Layout>
    <Helmet title={`${zone.nom_ppal} - Espace d'activités`} />
  </>
);

export default ZonePage;
