import React from 'react';

import { HTMLTable } from '@blueprintjs/core';

import { useFields } from '../hooks/useFields';

import FieldDesc from './FieldDesc';
import RenderValue from './RenderValue';

import styles from './ZoneRawData.module.scss';

const ZoneRawData = ({ zone }) => {
  const keysOrdering = useFields()
    .filter(({ data: { ShowInList, Ressource } }) => (ShowInList && Ressource === 'eae'))
    .map(({ data: { Key } }) => Key);

  return (
    <div>
      <HTMLTable
        bordered
        striped
        condensed
        className={styles.main}
      >
        <thead>
          <tr>
            <th>Clé</th>
            <th>Valeur</th>
          </tr>
        </thead>
        <tbody>
          {keysOrdering
            .map(field => (
              Object.prototype.hasOwnProperty.call(zone, field) && typeof zone[field] !== 'object' && (
                <tr key={field}>
                  <td><FieldDesc field={field} /></td>
                  <td><RenderValue value={zone[field]} /></td>
                </tr>
              )
            ))}
        </tbody>
      </HTMLTable>
    </div>
  );
};

export default ZoneRawData;
